<template>
    <div class="header">
         <div class='top'>
            <div>
                <!-- <img src="@/assets/sd-logo-title.0d2aa2e8.png" style="height:100%" alt=""> -->
            </div>
            <ul>
                <li>
                    <router-link to="/home" active-class='ative'>首页</router-link>
                </li>
                <li>
                    <router-link to="/solution" active-class='ative'>解决方案</router-link>
                </li>
                <li>
                    <router-link to="/home" active-class='ative'>技术优势</router-link>
                </li>
                <li>
                    <router-link to="/home" active-class='ative'>服务客户</router-link>
                </li>
                 <li>
                    <router-link to="/information" active-class='ative'>前沿资讯</router-link>
                </li> 
                <li>
                    <router-link to="/aboutus" active-class='ative'>关于我们</router-link>
                </li>
            </ul>
            <p @click="jumpLogin">云中医馆登陆/注册</p>
        </div>
         <!-- <router-link to="/home" active-class='ative'>首页</router-link> -->
        <div class="swiper-container2 swiper-container">
            <div class="swiper-wrapper ">
                <div class="swiper-slide" v-for="(item,index) in list" :key="index" >
                   
                    <img :src="item.imgurl" v-if='item.imgurl' style="width:100%;height:100%" alt="">
                     <img v-else src="@/assets/bannerHomePage.png" style="width:100%;height:100%" alt="">

                </div>
            </div>
            <!-- 如果需要分页器 -->
            <!-- <div class="swiper-pagination"></div> -->

            <!-- 如果需要导航按钮 -->
            <!-- <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div> -->

            <!-- 如果需要滚动条 -->
            <!-- <div class="swiper-scrollbar"></div> -->
        </div>
       
    
        <!-- <div class=bg>
             <img src="@/assets/29.jpg" style="width: 100%; max-height: 100%" alt="">
        </div> -->
        <div style="width:1200px;margin:0 auto">
            <ul class="homeimage">
                <li>
                    <div>河南智方宝信息科技有限公司</div>
                    <p>手机在手，看病不愁</p>
                </li>  
                <li>
                    <div   :class="isphone==1? 'left act':'left'" @click="clickdown(1)" >苹果版</div>
                    <div   :class="isphone==2? 'right act':'right'" @click="clickdown(2)" >安卓版</div>
                </li>  
                <li v-if='isphone==1' class="animate__animated animate__fadeInBottomLeft">
                    <div>
                        <div style="width:100px;height:100px;border:1px solid #fff">
                            <img src="@/assets/1.png" style="width:100%;height:100%" alt="">
                        </div>
                        <div style='margin-top:20px;text-align:center;'>中医医生版IOS</div>
                    </div>
                    <div>
                        <div style="width:100px;height:100px;border:1px solid #fff"> <img src="@/assets/1.png" style="width:100%;height:100%" alt=""></div>
                        <div style='margin-top:20px;text-align:center;'>社区医生版IOS</div>
                    </div>
                    <div>
                        <div style="width:100px;height:100px;border:1px solid #fff"> <img src="@/assets/1.png" style="width:100%;height:100%" alt=""></div>
                        <div style='margin-top:20px;text-align:center;'>中医医生版IOS</div>
                    </div>
                </li>  
                  <li v-if='isphone==2' class="animate__animated animate__fadeInBottomRight">
                    <div>
                        <div style="width:100px;height:100px;border:1px solid #fff">
                            <img src="@/assets/1.png" style="width:100%;height:100%" alt="">
                        </div>
                        <div style='margin-top:20px;text-align:center;'>中医医生版</div>
                    </div>
                    <div>
                        <div style="width:100px;height:100px;border:1px solid #fff"> <img src="@/assets/1.png" style="width:100%;height:100%" alt=""></div>
                        <div style='margin-top:20px;text-align:center;'>社区医生版</div>
                    </div>
                    <div>
                        <div style="width:100px;height:100px;border:1px solid #fff"> <img src="@/assets/1.png" style="width:100%;height:100%" alt=""></div>
                        <div style='margin-top:20px;text-align:center;'>中医医生版</div>
                    </div>
                </li>   
            </ul>
        </div>
    </div>
</template>

<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from "swiper"
import {getcarousel} from "../api/home"
export default {
    name:"Header",
    data(){
        return{
          isphone:1,
          list:[
              {
                  imgurl:""
              }
          ],
        }
    },
   async mounted(){
    new Swiper('.swiper-container2', {
		//direction: 'vertical', // 垂直切换选项
        mousewheel: false, //滚轮
        observer: true,
        observeParents: true, 
        parallax : true,
        // autoplay:false,
		autoplay: { //自动开始
            delay: 3000, //时间间隔
            stopOnLastSlide:false,
			disableOnInteraction: false, //*手动操作轮播图后不会暂停*
		},
		loop: true, // 循环模式选项
    	// 如果需要分页器
		// pagination: {
		// 	el: '.swiper-pagination',
        //     clickable: true, // 分页器可以点击
            
		// },
		// 如果需要前进后退按钮
		// navigation: {
		// 	nextEl: '.swiper-button-next',
		// 	prevEl: '.swiper-button-prev',
        // },
        // slidesPerView: 4,
        // spaceBetween: 10,
        // grid: {
        //     fill: 'column',
        //     rows: 1,
        // },
    //    on: {
    //         slideChangeTransitionStart: function(){
    //             // alert(this.activeIndex);
    //             // console.log(this.activeIndex)
    //         },
    //    }
		
		// 如果需要滚动条
		// scrollbar: {
		// 	el: '.swiper-scrollbar',
		// },
    }) 
    let r= await getcarousel()
    console.log(r)
    this.list=r.data
    },
    methods:{
         clickdown(type){
            this.isphone=type
        },
        jumpLogin(){
            return
            this.$router.push('/login')
        }
    }
}
</script>


<style class="header" scoped>
.header{
    width: 100%;
    min-width: 1200px;
    /* height: 40px; */
    /* height: 3rem; */
    /* background: rgba(40, 139, 234); */
    position: relative;
}
.top{
    width: 1200px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    left: 3vw;
    /* justify-content: space-between; */
    /* height: 80px; */
     color: #fff;
     position: relative;
     top:0 ;
     z-index: 100;
     /* left:11% */
}
.top>div{
    height:40px;
    width: 25%;
}
.top>ul{
    width: 60%;
    display: flex;
    justify-content: space-around;
}

.top>p{
    width: 15%;
    text-align: center;
    font-size: 15px;
    color: #fff;
    text-decoration:underline;
    cursor:pointer;

}
.bg{
  width:100%;height:400px;background:url("~@/assets/bannerHomePage.png");
  background-repeat:no-repeat;
  background-size:100% 100%;
  /* background-attachment:fixed; */
}
.homeimage{
    position: absolute;
    padding-left: 80px;
    top: 130px;
    color: #fff;
    z-index: 10;
}
.homeimage>li:nth-child(1){
    font-size: 30px;
}
.homeimage>li:nth-child(1)>p{
    font-size: 18px;
    margin-top: 20px;
}
.homeimage>li:nth-child(2){
    margin: 30px 0;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    margin-top: 60px;
    
}
.homeimage .left,.right{
    width: 50%;
    background: rgb(41, 127, 233);
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 14px;
    border:1px solid #fff;
    cursor:pointer;
} 
.homeimage .left{
    border-top-left-radius:10px ;
    border-bottom-left-radius:10px ;
    border-right: none ;
}
.homeimage .right{
    border-top-right-radius:10px ;
    border-bottom-right-radius:10px ;
}
.homeimage .act{
    color: rgb(41, 127, 233);
    background: #fff;
}
.homeimage>li:nth-child(3){
    /* width: 400px; */
    height: 150px;
    /* border: 1px solid #fff; */
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    
}
.homeimage>li:nth-child(3)>div{
    width: 100px;
    margin-right: 80px;
}
.homeimage>li:nth-child(3)>div>div:last-child{
    width: 90px;
    margin: 0 auto;
    font-size: 12px;
    border: 1px solid #fff;
    height: 25px;
    line-height: 25px;
    border-radius: 50px;
}
.swiper-container2{
    top: -40px;
    height: 550px;
}


</style>