import request from '@/utils/request.js';
export const getcarousel = (params) => {
    return request.get('/zfb_carousel',params)
  }
  export const getinfo = (params) => {
    return request.get('/info',params)
  }
  export const getcustom = (params) => {
    return request.get('/custom',params)
  }
  export const PostSubFoot = (params) => {
    return request.post('/msg_sumbit',params)
  }