import axios from 'axios'
import { Notification, MessageBox, Message, Loading } from 'element-ui'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
let downloadLoadingInstance;
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL:'http://1.13.172.248:8000/api',
    // 超时
    timeout: 10000
  })
  // request拦截器
service.interceptors.request.use(config => {
   
    return config
  }, error => {
      console.log(error)
      Promise.reject(error)
  })
  
  // 响应拦截器
  service.interceptors.response.use(res => {
     return res
    },
    error => {
      return Promise.reject(error)
    }
  )
  //封装post,get方法
const http = {
    get(url='',params={}){
       downloadLoadingInstance = Loading.service({ text: "正在加载...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
      return new Promise((resolve, reject) => {
        service({
          url,
          params,
          headers:{'Content-Type': 'application/json;charset=UTF-8'},
          method: 'GET'
        }).then(res => {
          resolve(res.data)
          downloadLoadingInstance.close();
          return res
        }).catch(error => {
          reject(error)
          Message.error(error)
          downloadLoadingInstance.close();
        })
      })
    },
    post(url='',params={}){
      downloadLoadingInstance = Loading.service({ text: "正在加载...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
      return new Promise((resolve, reject) => {
        service({
          url,
          data:params,
          headers:{'Content-Type': 'application/json;charset=UTF-8'},
          method: 'POST'
        }).then(res => {
          resolve(res.data)
          downloadLoadingInstance.close();
          return res
        }).catch(error => {
          reject(error)
          Message.error(error)
          downloadLoadingInstance.close();
        })
      })
    }
  }

  export default http