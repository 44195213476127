<template>
    <div class="foot">
        <div class="box">
            <ul>
                <li>
                    <h2>河南智方宝信息科技有限公司</h2>
                    <p>电话：0371-86170449</p>
                    <p>邮箱：henazhifangbao@163.com</p>
                    <p>传真：0371-86170449</p>
                    <p>邮编：518131</p>
                    <p>地址：河南自贸试验区郑州片区（郑东）金水东路49号3号楼C座13层253号</p>
                </li>
                <li>
                    <h2>产品解决方案</h2>
                    <p>各老中医经验智能化传承系统</p>
                    <p>中医临床智能辅助决策系统</p>
                    <p>各老中医专家专病系统</p>
                    <p>云中医门诊工作系统</p>
                    <p>临床科研数据收集和管理系统</p>
                    <p>临床科研项目管理系统</p>
                </li>
                <li>
                    <h2>官方机构合作平台</h2>
                    <p>河中研附院互联网医院</p>
                    <p>河南省中医治疗睡眠障碍协同创新中心管理系统</p>
                    <p>河南省中医疑难病会诊中心管理系统</p>
                    <p>河南省住院医师规范化培训基地管理系统</p>
                    <p>河南省中医继续教育管理系统</p>
                </li>
            </ul>
            <div>
                <el-input v-model="name"  placeholder="姓名"/>
                <el-input v-model="area"  placeholder="所在地区"/>
                <el-input v-model="unit"  placeholder="单位名称"/>
                <el-input v-model="tel" placeholder="手机号"/>
                <el-input v-model="requirement" placeholder="产品需求"/>
                <el-button type="primary" style="width:100%;margin-top:10px;" size="mini" @click="SubFoot">提交</el-button>
            </div>
        </div>
        <div style="width:80%;margin:20px auto 0;text-align:center;font-size: 12px;" class="color">
           <a href="https://beian.miit.gov.cn/">河南智方宝信息科技有限公司 豫ICP备2021010602号-3</a>
        </div>
    </div>
    
  
</template>

<script>
import {PostSubFoot} from "../api/home"
export default {
    name:"Foot",
    data(){
        return {
            name:"",
            area :"",
            unit :"",
            tel:"",
            requirement:"",
        }
    },
    methods:{
       async SubFoot(){
           if(!this.name.trim||!this.area.trim()||!this.unit.trim()||!this.tel.trim()||!this.requirement.trim()){
               return this.$message.error('请填写完整提交')
           }
           let data={
               name:this.name,
               area:this.area,
               unit:this.unit,
               tel:this.tel,
               requirement:this.requirement
           }
          
           let res= await PostSubFoot(data)
            if(res.code!=200) return this.$message.error(res.msg) 
            this.$message.success('提交成功')
           this.name=''
           this.area=''
           this.unit=''
           this.tel=''
           this.requirement=''
       }
    }
}
</script>

<style scoped>
.color{
    color:rgb(140, 140, 140) !important
}
.foot{
    /* position: fixed; */
    /* bottom: 0; */
    /* z-index:9999; */
    width: 100%;
    min-width: 1200px;
    background: rgba(0, 0, 0, 0.856);
    /* height: 2.5rem;
     */
    /* height: 200px ; */
    padding: 40px 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.box{
    width: 1200px;
    display: flex;
}
.box>ul{
    width: 70%;
    display: flex;
    justify-content: space-between;
    color: #fff;
}
.box>ul>li>h2{
    font-size: 20px;
    /* font-weight: 600; */
    margin-bottom: 30px;
}
.box>ul>li>p{
    margin-top: 10px;
    font-size: 12px;
    color:rgb(140, 140, 140) 
}
.box>div{
    width: 30%;
    box-sizing: border-box;
    padding: 0 70px;
}
/deep/.el-input__inner{
    background: transparent !important;
    border: none !important;
    border-bottom: 1px solid rgb(78, 78, 78) !important;
    height: 30px;
    color: #fff;
    font-size: 13px;

}


</style>