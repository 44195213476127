<template>
<div class="homebody">
    <!-- 产品解决方案 官方合作 -->
    <div style="width:100%;background:#fff;padding-bottom:80px">
        <div class="lbbox">
                <div class="lb_title">
                    <div @click="clicklb(1)">
                        <p> 产品解决方案</p>
                        <div class="heng" :class="index==1?'hengact':''"></div>
                    </div>
                    <div @click="clicklb(2)">
                        <p> 官方合作平台</p>
                        <div class="heng" :class="index==2?'hengact':''"></div>
                    </div>
                </div>


                <div class="swiper-container swiper-container3">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for='(item,index) in cplist' :key="index">
                            <div>
                                <div style="width:100px;height:100px">
                                    <img src="@/assets/1.png" style="width:100%;height:100px" alt="">
                                </div>
                            </div>
                            <div>
                                <p>{{item.title}}{{index}}</p>
                                <p>{{item.content}}</p>
                            </div>
                        </div>
                        
                    </div>
                    <!-- 如果需要分页器 -->
                    <!-- <div class="swiper-pagination"></div> -->

                    <!-- 如果需要导航按钮 -->
                    <div class="swiper-button-prev"></div>
                    <div class="swiper-button-next"></div>

                    <!-- 如果需要滚动条 -->
                    <!-- <div class="swiper-scrollbar"></div> -->
                </div>
        </div>
    </div>
    <!-- 技术优势 -->
    <div style="width:100%;padding:20px 0;background:rgb(251, 251, 251)">
        <div style="width:1200px;margin:40px auto">
           <div class="lb_title">
                    <div >
                        <p> 技术优势</p>
                        <div class="heng hengact" ></div>
                    </div>
            </div>
            <ul class="technology">
                <li>基于中医本体的名老中医诊疗经验知识图诺构建技术</li>
                <li>中医症状/体征规范化、结构化词语库构建技术</li>
                <li>中医体征/症状自然语言处理技术</li>
                <li>以证-机-方-药规律总结为目的的中医诊疗大数据挖据算法</li>
                <li>中医症状/体征规范化、结构化词语库构建技术</li>
                <li>2000+盖两千余个病种</li>
                <li>60+知名大型医院临床应用</li>

            </ul>
        </div>
    </div>
    <!-- 服务客户 -->
    <div style="width:100%;padding:20px 0;background:#fff">
        <div style="width:1200px;margin:40px auto">
            <div class="lb_title">
                <div >
                    <p> 服务客户</p>
                    <div class="heng hengact" ></div>
                </div>
             </div>
            <div class="fuwukehu">
                <div>
                    <img src="@/assets/map.png" style="width:100%;height:100%" alt="">
                </div>
                <div class="right">
                    <ul class="right-top">
                        <li :class="fwindex==0?'right-topact':''"  @click="fwClick(0)">标杆医院</li>
                        <li :class="fwindex==1?'right-topact':''" @click="fwClick(1)">等级医院</li>
                        <li :class="fwindex==2?'right-topact':''" @click="fwClick(2)">基层医院</li>
                    </ul>
                    <ul class='right-center'>
                        <li v-for="(item,index ) in fwlist " :key="index">
                            <div>
                                <img src="@/assets/08.jpg" style="width:100%;height:100%" alt="">
                            </div>
                            <div>
                                <h2 style="font-size:18px;margin-bottom:10px">{{item.title+index}}</h2>
                                <p>{{item.content}}</p>
                            </div>
                        </li>
                    </ul>
                    <!-- <ul class="right-bottom">
                        <li class='right-bottomact' >1</li>
                        <li>2</li>
                        <li>3</li>
                        <li>4</li>
                    </ul> -->
                    <el-pagination
                    background
                    layout="prev, pager, next"
                    :page-size=3
                    :total="total"
                    :current-page='page'
                    @current-change='changepage'>
                    </el-pagination>
                </div>                
            </div>


        </div>
    </div>
    <!-- 前言咨询 -->
    <div style="width:100%;padding:20px 0;background:rgb(251, 251, 251)" >
        <div style="width:1200px;margin:40px auto">
            <div class="lb_title">
                <div >
                    <p> 前沿咨询</p>
                    <div class="heng hengact" ></div>
                </div>
             </div>
             <ul class="qyzx">
                 <li v-for="(item,index) in qyzxlist" :key=index @click="JumpItem(item.id)">
                     <div>
                         <img src="@/assets/08.jpg" style="width:100%;height:100%" alt="">
                     </div>
                     <div>
                         <p>{{item.title}}</p>
                         <p>{{item.content}}</p>
                         <p>{{item.time}}</p>
                     </div>
                 </li>
                
             </ul>
             <div @click="jumpinformation" style=" text-align: right;margin-top:20px;font-size:12px;color:rgb(95, 158, 252); cursor: pointer;">
                 更多资讯》
             </div>
        </div>
    </div>
  
    
</div>
  
</template>

<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from "swiper"
import {getinfo,getcustom} from "../api/home"
export default {
    name:"Home",
    data(){
        return {
            fwindex:0,
            index:1,
            page:1,
            total:0,
            fwlist:[
                {
                    imgurl:"",
                    title:"江苏省中医院",
                    content :"江苏省中医院(南京中医药大学附属医院)位于江苏省南京市秦淮区汉中路155号,始建于1954年10月,是一所集医疗、康复、教学、科研于一体的三级甲等中医院,是南京市医保和新农合定点医院。"
                },
                {
                    imgurl:"",
                    title:"江苏省中医院",
                    content :"江苏省中医院(南京中医药大学附属医院)位于江苏省南京市秦淮区汉中路155号,始建于1954年10月,是一所集医疗、康复、教学、科研于一体的三级甲等中医院,是南京市医保和新农合定点医院。"
                },
                {
                    imgurl:"",
                    title:"江苏省中医院",
                    content :"江苏省中医院(南京中医药大学附属医院)位于江苏省南京市秦淮区汉中路155号,始建于1954年10月,是一所集医疗、康复、教学、科研于一体的三级甲等中医院,是南京市医保和新农合定点医院。"
                }
            ],
            qyzxlist:[
                {
                    id:"1",
                    imgurl:"",
                    title:"智方宝信息科技有限公司受邀参加'一帶一踏'中医药服务贸易国际合作研讨会",
                    content:"9月8日下午,中国国际服务贸易交易会重确活动-带一路,中医药服务贸易国际合作研讨会9月8日下午,中国国际服务贸易交易会重确活动-带一路,中医药服务贸易国际合作研讨会9月8日下午,中国国际服务贸易交易会重确活动-带一路,中医药服务贸易国际合作研讨会",
                    time:"2020-10-08"
                },{
                     id:"2",
                    imgurl:"",
                    title:"智方宝信息科技有限公司受邀参加'一帶一踏'中医药服务贸易国际合作研讨会",
                    content:"9月8日下午,中国国际服务贸易交易会重确活动-带一路,中医药服务贸易国际合作研讨会9月8日下午,中国国际服务贸易交易会重确活动-带一路,中医药服务贸易国际合作研讨会9月8日下午,中国国际服务贸易交易会重确活动-带一路,中医药服务贸易国际合作研讨会",
                    time:"2020-10-08"
                },{
                     id:"3",
                    imgurl:"",
                    title:"智方宝信息科技有限公司受邀参加'一帶一踏'中医药服务贸易国际合作研讨会",
                    content:"9月8日下午,中国国际服务贸易交易会重确活动-带一路,中医药服务贸易国际合作研讨会9月8日下午,中国国际服务贸易交易会重确活动-带一路,中医药服务贸易国际合作研讨会9月8日下午,中国国际服务贸易交易会重确活动-带一路,中医药服务贸易国际合作研讨会",
                    time:"2020-10-08"
               },{
                    id:"4",
                    imgurl:"",
                    title:"智方宝信息科技有限公司受邀参加'一帶一踏'中医药服务贸易国际合作研讨会",
                    content:"9月8日下午,中国国际服务贸易交易会重确活动-带一路,中医药服务贸易国际合作研讨会9月8日下午,中国国际服务贸易交易会重确活动-带一路,中医药服务贸易国际合作研讨会9月8日下午,中国国际服务贸易交易会重确活动-带一路,中医药服务贸易国际合作研讨会",
                    time:"2020-10-08"
                },
            ],
            cplist:[
                {
                imgurl:"",
                title:"名老中医经验智能化传承系统",
                content:"Intelligent inheritance system of traditionalChinese medicine experience"
                }, {
                imgurl:"",
                title:"名老中医经验智能化传承系统",
                content:"Intelligent inheritance system of traditionalChinese medicine experience"
                }, {
                imgurl:"",
                title:"名老中医经验智能化传承系统",
                content:"Intelligent inheritance system of traditionalChinese medicine experience"
                }, {
                imgurl:"",
                title:"名老中医经验智能化传承系统",
                content:"Intelligent inheritance system of traditionalChinese medicine experience"
                },{
                imgurl:"",
                title:"名老中医经验智能化传承系统",
                content:"Intelligent inheritance system of traditionalChinese medicine experience"
                },
            ]
              

        }
    },
  async  mounted(){
        new Swiper('.swiper-container3', {
		//direction: 'vertical', // 垂直切换选项
        mousewheel: false, //滚轮
        autoplay:false,
        observer: true,
        observeParents: true, 
		// autoplay: { //自动开始
        //     delay: 2500, //时间间隔
        //     stopOnLastSlide:false,
		// 	disableOnInteraction: false, //*手动操作轮播图后不会暂停*
		// },
		loop: false, // 循环模式选项
    	// 如果需要分页器
		pagination: {
			el: '.swiper-pagination',
            clickable: true, // 分页器可以点击
            
		},
		// 如果需要前进后退按钮
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
        },
        slidesPerView: 4,
        spaceBetween: 10,
        grid: {
            fill: 'column',
            rows: 1,
        },
    //    on: {
    //         slideChangeTransitionStart: function(){
    //             // alert(this.activeIndex);
    //             // console.log(this.activeIndex)
    //         },
    //    }
		
		// 如果需要滚动条
		// scrollbar: {
		// 	el: '.swiper-scrollbar',
		// },
    })
         let re=await getinfo()
         this.qyzxlist=re.data
         await this.GetCuston()
     

    },
    methods:{
      async  changepage(e){
            console.log(e)
            this.page=e
             await this.GetCuston()
        },
        async GetCuston(){
            let res=await getcustom({id:this.fwindex,page:this.page})
            this.fwlist=res.data
            this.total=res.total || 0
        },
       async fwClick(index){
            this.fwindex=index
            await this.GetCuston()
            this.page=1
        },
        jumpinformation(){
             this.$router.push({name:"Information"})
        },
         JumpItem(index){
            this.$router.push({name:"InformationItem",query:{id:index}})
        },
        clicklb(type){
            this.index=type
        }
    }
}
</script>


<style scoped>
.homebody{
    /* width: 1200px; */
    /* margin:0 auto; */
    width:100%
}
.homebody>div:nth-child(1){
    width: 100%;
    background: #fff;
    /* height: 3rem; */
   
}


.lbbox{
    width:1200px;
    /* border: 1px solid #ddd; */
    /* height: 5rem; */
    margin: 0 auto;
}
.swiper-container{
    height: 220px;
    display: flex !important;
    align-items: center !important;
    /* padding:0 70px; */
    width: 90%;
    margin: 0 auto;

}


.swiper-wrapper{
    width: 100px !important;
}
.swiper-slide{
    /* width: 300px !important; */
    height: 200px !important;
    /* border: 1px solid #ccc; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.swiper-slide>div{
    margin-bottom: 10px;
     display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.swiper-slide>div>p{
    text-align: center;
}
.swiper-slide>div>p:nth-child(1){
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;

}
.swiper-slide>div>p:nth-child(2){
     font-size:12px;
     /* font-weight: 600; */
     color: rgb(147, 147, 147);
}
.swiper-button-next{
    right: 0 !important;
    top: 80px;
}
.swiper-button-prev{
    left:0 !important;
    top: 80px;
}

.lb_title{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100px;
    align-items: center;
    font-size: 24px;
    margin-bottom: 30px;

}
.lb_title>div:nth-child(1){
    margin-right:60px;
}
.lb_title>div{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
.heng{
    width: 20px;
    height:4px;
    margin-top: 15px;
    border-radius: 50px;
}
.hengact{
    background: cornflowerblue;
}
.technology{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.technology>li{
    width:24.5%;
    height: 200px;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0 30px;
    line-height: 30px;
    text-align: center;
    font-size: 15px;
    
}
.technology>li:nth-child(4n-3){
    border-left: 1px solid #ccc;
}
.technology>li:hover{
    background: rgb(64, 64, 64);
    color: #fff;
}
.fuwukehu{
    display: flex;
    width: 100%;
}
.fuwukehu>div{
    width: 50%;
    /* border:1px solid #ccc; */
    height: 400px;
}
.right{
    box-sizing: border-box;
    padding: 30px;

}
.right .right-top{
    display: flex;
    width: 50%;
    justify-content: space-between;
    margin-bottom: 20px;
    cursor: pointer;
}
.right .right-top>li{
    padding: 5px 10px;
    border:1px solid rgb(25, 129, 252);
    color:rgb(25, 129, 252);
    font-size: 14px;
}
.right-topact{
    background: rgb(25, 129, 252);
    color:#fff !important;
}
.right .right-bottom{
    display: flex;
    width: 50%;
    cursor: pointer;
}
.right .right-bottom>li{
    padding: 5px 15px;
    border: 1px solid #ccc;
    margin-right:10px ;
}
.right-bottom>li.right-bottomact{
    color: #fff;
    background: rgb(25, 129, 252);
    border:1px solid rgb(25, 129, 252);
}
.right-center{
    width: 100%;
    height:320px;
    /* border: 1px solid #ccc; */
    overflow: auto;
    margin-bottom: 20px;
}
.right-center>li{
    display: flex;
    height: 100px;
    /* border: 1px solid #ccc; */
    width: 100%;
    margin-bottom: 10px;
    align-items: center;
    cursor: pointer;
}
.right-center>li:last-child{
    margin-bottom: 0;
}
.right-center>li>div:nth-child(1){
    width: 30%;
    height: 90px;
    /* border: 1px solid #ccc; */
}
.right-center>li>div:nth-child(2){
    width: 70%;
    box-sizing: border-box;
    padding-left: 20px;
    font-size: 14px;
}
.right-center>li>div:nth-child(2)>p{
    line-height: 16px;
}
.qyzx{
    width: 100%;
    display: flex;
    justify-content: space-between;

}
.qyzx>li{
    /* margin-right: 1%; */
    width: 24%;
    border: 1px solid #ccc;
    cursor: pointer;
    
}
.qyzx>li:last-child{
    margin-right: 0;
}
.qyzx>li>div:nth-child(1){
    width: 100%;
    height: 150px;
}
.qyzx>li>div:nth-child(2){
    width:96%;
    margin: 0 auto;
    margin-top: 10px;
}
.qyzx>li>div:nth-child(2)>p:nth-child(1){
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 16px;
    letter-spacing: 1px;
}
.qyzx>li>div:nth-child(2)>p:nth-child(2){
    font-size: 12px;
    font-weight: 400;
     margin-bottom: 10px;
      display: -webkit-box;
    overflow: hidden;
    white-space: normal !important;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

     color: rgb(158, 158, 158);
}
.qyzx>li>div:nth-child(2)>p:last-child{
     font-size: 12px;
    font-weight: 400;
     margin-bottom: 10px;
     color: rgb(158, 158, 158);
}




</style>