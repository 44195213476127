<template>
  <div class="right">
      <ul>
         <a href="#"><li ><img src="@/assets/shang.png"  alt=""></li></a> 
          <li><img src="@/assets/wechat.png" alt=""></li>
          <li><img src="@/assets/wb.png" alt=""></li>
          <li><img src="@/assets/phone.png" alt=""></li>
      </ul>
      
  </div>
</template>

<script>
export default {
  name: 'Right',
  data(){
      return{
          scrollTop:0
      }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollToTop)
    },
    methods:{
scrollToTop() { 
//   　　var scrollTop = document.documentElement.scrollTop
// //   || document.body.scrollTop; 
//     // if(scrollTop<400&&scrollTop>)
//   　　console.log(scrollTop) 
},
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.right{
    position: absolute;
    bottom: 25%;
    right: 5%;
}
.right>ul li{
    width: 40px;
    height: 40px;
    background: rgb(62, 131, 233);
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.right>ul li>img{
    width: 20px;
    height: 20px;
}
</style>
